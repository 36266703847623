<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        <b>{{
          inputs.language === 'en' ? 'Select the best answer' : 'Sélectionnez la meilleure réponse'
        }}</b
        >.
        {{
          inputs.language === 'en'
            ? 'The melting point of a specific compound is proportional to:'
            : "Le point de fusion d'un composé spécifique est proportionnel à:"
        }}
      </p>

      <v-radio-group
        v-model="inputs.meltingPointProportionalTo"
        :disabled="!allowEditing"
        class="mb-0 pl-6"
      >
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab2Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        meltingPointProportionalTo: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'The number of carbon atoms', value: 'numCarbonAtoms'},
        {text: 'The number of functional groups', value: 'numFunctionalGroups'},
        {text: 'The size of the molecule', value: 'size'},
        {text: 'The number and type of possible intermolecular interactions', value: 'IMFs'},
        {text: 'The specific heat capacity of the compound', value: 'specificHeat'},
      ],
      optionsFr: [
        {text: "Le nombre d'atomes de carbon", value: 'numCarbonAtomsFr'},
        {text: 'Le nombre de groupements fonctionnels', value: 'numFunctionalGroupsFr'},
        {text: 'La taille de la molécule', value: 'sizeFr'},
        {
          text: 'Le nombre et la type des intéractions intermoléculaires possibles',
          value: 'IMFs',
        },
        {text: 'La capacité thermique massique du composé', value: 'specificHeatFr'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
